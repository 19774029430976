import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import t from "../utils/translate"

import fbSrc from "../images/fb.svg"
import igSrc from "../images/ig.svg"
import inSrc from "../images/in.svg"

import styles from "./contact.module.css"

export const query = graphql`
  query($id: String!) {
    sanityPage(id: { eq: $id }) {
      ...CommonPage
    }
    sanityContact {
      address1
      address2
      phone
      email
      facebook
      instagram
      linkedin
    }
  }
`

const HomepageTemplate = ({
  data: { sanityPage: page, sanityContact },
  pageContext: { locale, strings },
}) => (
  <Layout locale={locale} mobileHeader>
    <Seo locale={locale} seofield={page.seo}></Seo>
    <div className={styles.abContainer}>
      <h1 className={styles.h1}>{t(strings.contactsus, locale)}</h1>
    </div>
    <div className={styles.listContainer}>
      <div>
        <h2 className={styles.title}>{t(page.title, locale)}</h2>
      </div>
      <div>
        <div className={styles.data}>
          <div>
            <address>{sanityContact.address1}</address>
            <address>{sanityContact.address2}</address>
          </div>
        </div>
      </div>
      <div className={styles.data}>
        <div>T {sanityContact.phone}<br/>{sanityContact.email}</div>
      </div>
      <div className={styles.social}>
        <a href={sanityContact.facebook} target="_blank" rel="noreferrer">
          <img src={fbSrc} alt="fb ico" />
        </a>
        <a href={sanityContact.instagram} target="_blank" rel="noreferrer">
          <img src={igSrc} alt="ig ico" />
        </a>
        <a href={sanityContact.linkedin} target="_blank" rel="noreferrer">
          <img src={inSrc} alt="lk ico" />
        </a>
      </div>
      {
      /*
      <div>
        <h2 className={styles.title}>{t(page.title, locale)}</h2>
        <div className={styles.data}>
          <div>
            <address>{sanityContact.address1}</address>
            <address>{sanityContact.address2}</address>
          </div>
          <div>
            <div>T {sanityContact.phone}</div>
            <div>{sanityContact.email}</div>
          </div>
        </div>
        <div className={styles.social}>
          <a href={sanityContact.facebook} target="_blank" rel="noreferrer">
            <img src={fbSrc} alt="fb ico" />
          </a>
          <a href={sanityContact.instagram} target="_blank" rel="noreferrer">
            <img src={igSrc} alt="ig ico" />
          </a>
          <a href={sanityContact.linkedin} target="_blank" rel="noreferrer">
            <img src={inSrc} alt="lk ico" />
          </a>
        </div>
      </div>
      */
      }
    </div>
  </Layout>
)

export default HomepageTemplate
